import { DomainModel } from '@domain/domain.model';

export class User extends DomainModel {

  // Configuration
  public entity = 'user';
  public table = 'users';
  public schema = '++id, remote_id, role_id, name_lcase';
  public sync = false;

  // Fields
  public role_id: number;
  public name: string;
  public name_lcase: string;
  public email: string;
  public locale_id: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
