import { DomainModel } from '@domain/domain.model';
import { Module } from './module.model';

export class ModuleSetting extends DomainModel {
  public static settings: ModuleSetting[];

  // Configuration
  public entity = 'module-setting';
  public table = 'module_settings';
  public schema = '++id, module_id, tenant_id, name';
  public sync = false;

  // Fields
  public tenant_id: number;
  public module_id: number;
  public name: string;
  public type: string;
  public description: string;
  public value: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Load current setting in static cache
   */
  public static async loadSettings(): Promise<void> {
    ModuleSetting.settings = await ModuleSetting.query.toArray();
  }

  /**
   * Get setting value
   */
  public static getValue(moduleName: string, name: string): string | undefined {
    if (!ModuleSetting.settings) {
      return undefined;
    }

    const module: Module = Module.getByName(moduleName);

    if (!module) {
      return undefined;
    }

    const moduleSetting: ModuleSetting = ModuleSetting.settings.find(setting => setting.name === name && setting.module_id === module.id);
    return moduleSetting ? moduleSetting.value : null;
  }
}
