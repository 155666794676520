import { Component, Input, OnInit } from '@angular/core';
import { LegendItem } from '@shared/components/table-legend/legendItem';

@Component({
  selector: 'app-table-legend',
  templateUrl: './table-legend.component.html',
  styleUrls: ['./table-legend.component.scss']
})
export class TableLegendComponent implements OnInit {
  @Input() public legendItems: LegendItem[];

  constructor() { }

  ngOnInit() {
  }
}
