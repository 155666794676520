import { DomainModel } from '@domain/domain.model';

export class ModuleTenant extends DomainModel {
  public static moduleTenants: ModuleTenant[];

  // Configuration
  public entity = 'module-tenant';
  public table = 'module_tenant';
  public schema = 'id';
  public sync = false;

  // Fields
  public id: string;
  public name: string;
  public available_offline: boolean;
  public translation_key: string;
  public menu_index: number;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Load current modules in static cache
   */
  public static async loadModuleTenants(): Promise<void> {
    ModuleTenant.moduleTenants = await ModuleTenant.query.toArray();
  }

  public getData(): any {
    return {
      id: this.id,
      name: this.name,
      available_offline: this.available_offline,
      translation_key: this.translation_key,
      menu_index: this.menu_index
    };
  }
}
