import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from '@node_modules/rxjs';
import 'rxjs/add/operator/map'
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@emendis/auth';
import User from '@emendis/auth/lib/user.interface';
import { LocaleService } from '@shared/services/locale.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public locales$: Observable<any[]>;
  public currentLocale: MenuItem;
  public profileMenuItems: MenuItem[];
  public currentUser: User;
  public settingPermissions: string[];

  constructor(
    private localeService: LocaleService,
    private translateService: TranslateService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {
    this.currentUser = this.authService.getUser();

    // Define all permissions that should be able to see the manage section
    this.settingPermissions = [
      'user.manage.tenant',
      'tenant.manage',
      'module.manage.tenant',
      'translation.manage.tenant',
      'role.manage.tenant'
    ];

    this.profileMenuItems = [
      {
        label: 'Profile',
        icon: 'fas fa-user-circle',
        routerLink: '/admin/users/profile'
      },
      {
        label: 'Logout',
        icon: 'fas fa-sign-out-alt',
        command: () => {
          this.logout();
        }
      }
    ];

    const currentLocaleCode = LocaleService.getCurrentLocaleCode();

    this.locales$ = this.localeService.index().map(response =>
      response.data.map(item => {
        const localeItem: MenuItem = {
          id: item.code,
          label: item.name,
          icon: 'flag-icon flag-icon-' + item.code,
          command: (event: any) => {
            this.switchLanguage(event.item);
          }
        };

        if (item.code === currentLocaleCode || item.id === currentLocaleCode) {
          this.currentLocale = localeItem;
        }

        return localeItem;
      })
    );
  }

  /**
   * Set the current language
   *
   * @param localeItem
   */
  public switchLanguage(localeItem: MenuItem): void {
    this.currentLocale = localeItem;
    this.translateService.use(localeItem.id);
    LocaleService.setCurrentLocaleCode(localeItem.id);
  }

  /**
   * Navigate to given url
   *
   * @param url
   */
  public navigate(url): void {
    this.router.navigate([url]);
  }

  /**
   * Logout and redirect to login screen
   */
  public logout(): void {
    this.authService.logout().subscribe(result => {
      if (result) {
        // Navigate to admin part
        this.navigate('/auth/login');
      }
    });
  }
}
