import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Tenant } from '@domain/models/tenant.model';
import { Observable } from '@node_modules/rxjs';

@Injectable()
export class TenantService extends ApiService<Tenant> {
  protected endpoint = 'tenant';

  // Backend not implemented yet
  public dummyData = [
    {
      id: 1,
      name: 'PA van Rooyen',
      email: 'info@pavanrooyen.nl'
    },
    {
      id: 2,
      name: 'Lagerberg',
      email: 'info@lagerbergverhuisgroep.com'
    },
    {
      id: 3,
      name: 'Holwerda',
      email: 'info@holwerda.nl'
    },
    {
      id: 3,
      name: 'van Leeuwen',
      email: 'info@vanleeuwen-verhuizingen.nl'
    }
  ];

  // TODO: Remove when backend is implemented
  public index(options?) {
    return Observable.create(observer => {
      setTimeout(() => {
        console.warn('WARNING: DUMMY DATA WAS RETURNED HERE');
        observer.next({data: this.dummyData});
      }, 500);
    });
  }
}
