import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: 'loginLayout.component.html',
  styleUrls: ['./loginLayout.component.scss']
})
export class LoginLayoutComponent {

  public appName: string;

  public constructor() {
    this.appName = environment.appName;
  }

}
