import { DomainModel } from '@domain/domain.model';

export class Translation extends DomainModel {
  public static translations: Translation[];

  // Configuration
  public entity = 'translation';
  public table = 'translations';
  public schema = '++id';
  public sync = false;

  // Fields
  public id: string;
  public value: any;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Load current translations in static cache
   *
   * @return Promise<void>
   */
  public static async loadTranslations(): Promise<void> {
    Translation.translations = await Translation.query.toArray();
  }
}
