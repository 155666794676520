import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic',
  templateUrl: 'popupLayout.component.html'
})
export class PopupLayoutComponent implements OnInit {

  public ngOnInit(): void {
  }
}
