import { Subject } from 'rxjs';
import Dexie from 'dexie';
import { DomainModel } from '@domain/domain.model';
import { User } from '@domain/models/user.model';
import { Module } from '@domain/models/module.model';
import { Setting } from '@domain/models/setting.model';
import { SettingService } from '@shared/services/setting.service';
import { AppInjector } from '@root/src/app/app-injector.service';
import { Translation } from '@domain/models/translation.model';
import { Permission } from '@domain/models/pemission.model';
import { BaseDataKey } from '@domain/models/base-data-key.model';
import { BaseDataValue } from '@domain/models/base-data-value.model';
import { ModuleTenant } from '@domain/models/module-tenant.model';
import { Tenant } from "@domain/models/tenant.model";
import { ModuleSetting } from "@domain/models/module-setting.model";

export class Store extends Dexie {
  private static _store: Store = null;
  private static _isReady: boolean;
  private static _isLoading: boolean;
  public static onReady: Subject<boolean> = new Subject<boolean>();

  private currentDbVersion = 1;
  public entities: Dexie.Table<DomainModel, number>[];

  // Define all models to be used in the Dexie database here
  public models: Array<any> = [
    BaseDataValue,
    BaseDataKey,
    Module,
    ModuleSetting,
    ModuleTenant,
    Permission,
    Setting,
    Tenant,
    Translation,
    User
  ];

  /**
   * Create indexedDB tables
   */
  constructor() {
    super('complete_db', { autoOpen: true });
  }

  public async setup(): Promise<boolean> {
    if (Store._isReady) {
      return true;
    }

    if (Store._isLoading) {
      return await Store.onReady.toPromise();
    }

    Store._isLoading = true;

    if (await Dexie.exists('complete_db')) {
      await this.open();

      if (this.verno < this.currentDbVersion) {
        // Delete current outdated database if applicable
        await this.delete();
        await this.version(1).stores({});
      } else {
        await this.close();
      }
    }

    // Generate schema object
    const schema: any = {};

    // Add each model
    this.models.forEach(model => {
      const m = model.getInstance();
      schema[m.table] = m.schema;
    });

    // Create schema
    await this.version(this.currentDbVersion).stores(schema);
    await this.open();

    DomainModel.store = this;

    // Map each table to class model
    this.models.forEach(model => {
      const m = model.getInstance();
      this[m.table].mapToClass(model);
    });

    Store._isReady = true;
    Store._isLoading = false;
    Store.onReady.next(true);
    Store.onReady.complete();

    await this.onStoreReady();

    return true;
  }

  /**
   * Resets the database by removing and re-creating it
   */
  public async reset() {
    await this.delete();
    await this.setup();
  }

  /**
   * Return singleton instance
   */
  public static getStore(): Store {
    if (Store._store === null) {
      Store._store = new Store();
    }

    return Store._store;
  }

  /**
   * Hook after store is ready
   */
  private async onStoreReady(): Promise<void> {
    // Init tenant and module settings
    const injector = AppInjector.getInjector();
    const settingsService: SettingService = injector.get(SettingService);
  }
}
