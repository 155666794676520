import { Injectable } from '@angular/core';
import { AuthService } from '@emendis/auth';
import { ApiHttpService, ApiService } from '@emendis/api';
import { User } from '@domain/models/user.model';
import * as jwtDecode from 'jwt-decode';
import { map } from '@node_modules/rxjs/internal/operators';

@Injectable()
export class UserService extends ApiService<User> {
  protected endpoint = 'user';

  constructor(public apiHttp: ApiHttpService, private auth: AuthService) {
    super(apiHttp);
  }

  /**
   * Checks if the current user has the given role
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === roleCode;
  }

  /**
   * Checks if the current user has superuser rights
   */
  public isSuperuser(): boolean {
    return this.hasRole('superuser');
  }

  /**
   * Checks if the current user has administrator rights
   */
  public isAdministrator(): boolean {
    return this.hasRole('superuser') || this.hasRole('administrator');
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    // Get current JWT token
    const jwtToken = this.auth.getToken();

    // Decode JWT token
    return jwtDecode(jwtToken);
  }
}
