import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Libraries, 3d party components
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {
  ButtonModule,
  ConfirmDialogModule,
  DataTableModule, DialogModule,
  DropdownModule,
  InputSwitchModule,
  MessageService,
  MultiSelectModule,
  SelectButtonModule,
  TooltipModule
} from 'primeng/primeng';
import { FormModule } from '@emendis/formly';
import { SharedModule as EmendisSharedModule } from '@emendis/shared';
import { TableModule as EmendisTableModule } from '@emendis/table';

// Custom components
import { AppPatternRestrictDirective } from '@shared/directives/app-pattern-restrict.directive';
import { SettingPipe } from '@shared/pipes/setting.pipe';
import { SettingColorDirective } from './directives/setting-color.directive';
import { IfSettingDirective } from '@shared/directives/if-setting.directive';
import { EmendisUiModule } from '@emendis/ui';
import { TableLegendComponent } from './components/table-legend/table-legend.component';

@NgModule({
  declarations: [
    AppPatternRestrictDirective,
    SettingPipe,
    SettingColorDirective,
    IfSettingDirective,
    TableLegendComponent,
  ],
  imports: [
    FormsModule,
    DataTableModule,
    TableModule,
    ReactiveFormsModule,
    EmendisTableModule.forRoot(TranslateService),
    TooltipModule,
    MultiSelectModule,
    ButtonModule,
    DropdownModule,
    ConfirmDialogModule,
    InputSwitchModule,
    SelectButtonModule,
    DialogModule,
    FormlyModule.forRoot({
      validationMessages: [
        {
          name: 'required',
          message: 'Dit veld is verplicht'
        },
        {
          name: 'uniqueClientName',
          message: 'Deze naam is al in gebruik'
        },
        {
          name: 'email',
          message: 'Vul een geldig e-mailadres in.'
        },
        {
          name: 'password',
          message: 'Het wachtwoord moet minstens 6 karakters lang zijn, 1 hoofdletter, 1 nummer en een speciaal teken bevatten.'
        }
      ]
    }),
    ToastModule,
    EmendisUiModule,
    EmendisSharedModule.forRoot(MessageService)
  ],
  exports: [
    EmendisTableModule,
    AppPatternRestrictDirective,
    SettingPipe,
    MultiSelectModule,
    ButtonModule,
    DropdownModule,
    ConfirmDialogModule,
    InputSwitchModule,
    SelectButtonModule,
    FormlyModule,
    FormModule,
    TranslateModule,
    SettingColorDirective,
    IfSettingDirective,
    EmendisUiModule,
    NgxPermissionsModule,
    TableLegendComponent
  ]
})
export class SharedModule {
}
