import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@shared/guards/auth.guard';
import { BasicLayoutComponent } from '@core/layouts/basicLayout.component';
import { LoginLayoutComponent } from '@core/layouts/loginLayout.component';
import { NgxPermissionsGuard } from '@node_modules/ngx-permissions';
import {
  BaseDataModule,
  ConfigOverviewComponent,
  IndexedDbGuard,
  ModuleResolver,
  ModuleTenantResolver,
  PermissionResolver,
  RoleModule,
  SettingModule,
  SettingResolver,
  TenantModule,
  TranslationModule,
  TranslationResolver,
  UserModule
} from '@emendis/complete';

const routes = [
  {
    path: 'auth',
    component: LoginLayoutComponent,
    loadChildren: './features/auth/auth.module#AuthModule'
  },
  {
    path: 'admin',
    canActivate: [IndexedDbGuard, AuthGuard],
    canActivateChild: [AuthGuard],
    component: BasicLayoutComponent,
    resolve: {
      settings: SettingResolver,
      permissions: PermissionResolver,
      modules: ModuleResolver,
      translations: TranslationResolver,
      moduleTenants: ModuleTenantResolver
    },
    children: [
      {
        path: 'config',
        component: ConfigOverviewComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './features/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'planning',
        loadChildren: './features/planning/planning.module#PlanningModule'
      },
      {
        path: 'tenants',
        loadChildren: () => TenantModule,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'tenant.manage',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'base-data',
        loadChildren: () => BaseDataModule,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'base-data-value.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'translations',
        loadChildren: () => TranslationModule,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'translation.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'roles',
        loadChildren: () => RoleModule,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'role.manage.tenant',
            redirectTo: '/'
          }
        }
      },
      {
        path: 'users',
        loadChildren: () => UserModule
      },
      {
        path: 'settings',
        loadChildren: () => SettingModule
      }
    ]
  },
  {
    path: '',
    redirectTo: 'admin/dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'admin'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
