import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-basic',
  templateUrl: 'basicLayout.component.html',
  styleUrls: ['./basicLayout.component.scss']
})
export class BasicLayoutComponent implements OnInit {
  public version: string;
  public environment: any;
  public logoUrl: string;
  public items: Object[];

  public ngOnInit(): void {
    this.version = environment.version;
    this.logoUrl = 'https://pbs.twimg.com/media/CQkpO8tXAAAFt-U.jpg';
    this.items = [
      {
        title: 'Planning',
        icon: 'far fa-calendar-alt',
        routerUrl: '/admin/planning'
      },
      {
        title: 'Projecten',
        icon: 'fas fa-tasks',
        routerUrl: '/admin/projects'
      },
      {
        title: 'Medewerkers',
        icon: 'fas fa-users',
        routerUrl: '/admin/users'
      },
      {
        title: 'Machines',
        icon: 'fas fa-tractor',
        routerUrl: '/admin/vehicles'
      },
      {
        title: 'Verlofaanvragen',
        icon: 'fas fa-coffee',
        routerUrl: '/admin/leave-request'
      },
      {
        title: 'Urenregistratie',
        icon: 'far fa-clock',
        routerUrl: '/admin/time-registration'
      },
      {
        title: 'Bedrijven',
        icon: 'far fa-building',
        routerUrl: '/admin/companies'
      },
    ];
  }
}
