import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  appName: 'PlannerComplete',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://planner.complete.test.emendis.nl/api',
  sentryUrl: null,
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  databaseName: 'complete_planner_test'
};
