import { DomainModel } from '@domain/domain.model';
import { BaseDataValue } from '@domain/models/base-data-value.model';

export class BaseDataKey extends DomainModel {
  // Configuration
  public entity = 'base-data-key';
  public table = 'base_data_keys';
  public schema = '++id, module_id, key';
  public sync = true;

  // Fields
  public module_id?: string;
  public key: string;
  public values: BaseDataValue[];

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  public async init(): Promise<void> {
   await BaseDataValue.query.where({base_data_key_id: this.id}).toArray().then(result => {
      this.values = result;
    });
  }
}
