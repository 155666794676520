import { Pipe, PipeTransform } from '@angular/core';
import { Setting } from '@domain/models/setting.model';

/*
 * Retrieves tenant or module setting
 * Usage:
 *   settingName | setting:'module name or "tenant"'
 * Example:
 *   {{ name | setting:'tenant' }}
 *   formats to: "Tenant B.V."
 */
@Pipe({ name: 'setting', pure: false })
export class SettingPipe implements PipeTransform {
  transform(settingName: string, moduleName: string): string {
    return Setting.getValue(settingName) as string;
  }
}
