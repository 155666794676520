import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Setting } from '@domain/models/setting.model';
import { SettingService } from '@shared/services/setting.service';
import { Subject } from '@node_modules/rxjs';
import { takeUntil } from '@node_modules/rxjs/internal/operators';

/**
 * Directive to apply color styling based on given setting
 */
@Directive({
  selector: '[appSettingColor]'
})
export class SettingColorDirective implements OnDestroy, AfterViewInit {
  @Input() appSettingColor: string;
  @Input() styleProperty = 'backgroundColor';
  @Input() childSelector: string;

  private destroy$: Subject<void>;

  constructor(private element: ElementRef, private settingService: SettingService) {
    this.destroy$ = new Subject();
  }

  public ngAfterViewInit() {
    this.changeStyleAttribute();

    this.settingService.settingsChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.changeStyleAttribute();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  /**
   * Set the given style attribute to the given setting color
   */
  private changeStyleAttribute(): void {
    if (this.childSelector) {
      this.element.nativeElement.querySelector('.ui-dropdown').style[this.styleProperty] = Setting.getValue(this.appSettingColor) || '#00999c';
    } else {
      this.element.nativeElement.style[this.styleProperty] = Setting.getValue(this.appSettingColor) || '#00999c';
    }
  }

}
