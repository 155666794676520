import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
// NGX
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsGuard, NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { FormlyModule } from '@node_modules/@ngx-formly/core';
// PrimeNG
import { ConfirmDialogModule, MessageService } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
// Emendis
import { ApiModule } from '@emendis/api';
import { AuthModule as EmendisAuthmodule, AuthService } from '@emendis/auth';
import { CoreModule as CompleteModule } from '@emendis/complete';
import { SharedModule } from '@emendis/shared';
import { FormModule } from '@emendis/formly';
// App services
import { AppInjector } from '@root/src/app/app-injector.service';
import { UserService } from '@shared/services/user.service';
import { TenantService } from '@shared/services/tenant.service';
import { DataService } from '@shared/services/data.service';
import { SettingService } from '@shared/services/setting.service';
// App modules/components
import { AppRoutingModule } from '@root/src/app/app-routing.module';
import { LayoutsModule } from '@core/layouts/layouts.module';
import { CoreModule } from '@core/core.module';
import { TranslationLoader } from '@core/translate/translation-loader';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
// Guards
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { AuthGuard } from '@shared/guards/auth.guard';
import { UserGuard } from '@shared/guards/user.guard';
// Sentry
import * as Raven from 'raven-js';

if (environment.production && environment.sentryUrl) {
  Raven.config(environment.sentryUrl).install();
}

export function onAuthError(): void {
  window.location.href = '/#/auth/login';
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err);
    } else {
      throw err;
    }
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    LayoutsModule,
    ConfirmDialogModule,
    NgxPermissionsModule.forRoot(),
    ToastModule,
    FormModule,
    FormlyModule.forRoot(),
    AppRoutingModule,
    EmendisAuthmodule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError: onAuthError
    }),
    CoreModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader
      }
    }),
    CompleteModule.forRoot({
      indexedDbModels: [],
      databaseName: environment.databaseName,
      version: 2
    }, NgxPermissionsService, NgxPermissionsModule),
    SharedModule.forRoot(MessageService)
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    UserGuard,
    NgxPermissionsGuard,
    AuthGuard,
    NgxPermissionsService,
    BootstrapGuard,
    AuthService,
    DataService,
    UserService,
    TenantService,
    SettingService,
    ConfirmationService,
    MessageService,
    TranslateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
