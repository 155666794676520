import { DomainModel } from '@domain/domain.model';

export class Permission extends DomainModel {
  public static permissions: Permission[];

  // Configuration
  public entity = 'permission';
  public table = 'permissions';
  public schema = '++id, name';
  public sync = false;

  // Fields
  public name: string;
  public description: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }

  /**
   * Load current permissions in static cache
   */
  public static async loadPermissions(): Promise<void> {
    Permission.permissions = await Permission.query.toArray();
  }

  /**
   * Get the permission by name
   *
   * @param name - the name of the permission
   *
   * @return the permission
   */
  public static getByName(name: string): Permission | undefined {
    if (!Permission.permissions) {
      return undefined;
    }

    return Permission.permissions.find(module => module.name === name);
  }
}
