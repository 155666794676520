import { Injectable } from '@angular/core';
import { ApiService } from '@emendis/api';
import { Locale } from '@shared/models/locale.model';

@Injectable({
  providedIn: 'root'
})
export class LocaleService extends ApiService<Locale> {
  protected endpoint = 'tenant/locale';

  /**
   * Get the currently set language
   */
  public static getCurrentLocaleCode(): string {
    const localeId = localStorage.getItem('currentLocaleCode');

    if (localeId) {
      return localeId;
    }

    return JSON.parse(localStorage.getItem('user')).locale_id;
  }

  /**
   * Set the current used locale id
   *
   * @param localeId
   */
  public static setCurrentLocaleCode(localeId: string): void {
    localStorage.setItem('currentLocaleCode', localeId);
  }
}
