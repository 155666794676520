import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';

import { BasicLayoutComponent } from './basicLayout.component';
import { BlankLayoutComponent } from './blankLayout.component';
import { LoginLayoutComponent } from './loginLayout.component';

import { PopupLayoutComponent } from '@core/layouts/popupLayout.component';
import { HeaderComponent } from '@core/components/header/header.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { EmendisSidebarMenuModule } from '@emendis/ui';

@NgModule({
  declarations: [
    HeaderComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    LoginLayoutComponent,
    PopupLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    MenuModule,
    DropdownModule,
    ToastModule,
    EmendisSidebarMenuModule
  ],
  exports: [
    HeaderComponent,
    BasicLayoutComponent,
    BlankLayoutComponent,
    LoginLayoutComponent,
    PopupLayoutComponent
  ]
})
export class LayoutsModule {}
